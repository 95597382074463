import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import '../assets/styles/base/standard-page.scss';
import '../assets/styles/pages/accident-management.scss';
import '../assets/styles/base/modern-page.scss';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ExternalLinkIcon from '../images/Icons/links/external.svg';
import ClubDinggoIllustration from '../images/club-dinggo-illustration.svg';
import IllustrationKeys from '../images/Icons/Illustrated/club/keys.svg';
import IllustrationPhone from '../images/Icons/Illustrated/club/phone.svg';
import IllustrationRating from '../images/Icons/Illustrated/club/rating.svg';
import IllustrationSpanner from '../images/Icons/Illustrated/club/spanner.svg';
import IllustrationSupport from '../images/Icons/Illustrated/club/support.svg';
import IllustrationTow from '../images/Icons/Illustrated/club/tow.svg';
import ProdIllustration1 from '../images/club-dinggo/steps/Prod-Illustration-1.png';
import ProdIllustration2 from '../images/club-dinggo/steps/Prod-Illustration-2.png';
import ProdIllustration3 from '../images/club-dinggo/steps/Prod-Illustration-3.png';
import ProdIllustration4 from '../images/club-dinggo/steps/Prod-Illustration-4.png';
import ProdIllustration5 from '../images/club-dinggo/steps/Prod-Illustration-5.png';

const ClubDinggo = () => {
  const data = useStaticQuery(graphql`
    query {
      general {
        country_code
      }
    }
  `);
  return (
    <Layout wrapperClass="modern-page" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO title="DingGo | Club Dinggo"/>
      <div className="main-wrapper modern-page">
        <section className="py-7 bg-lighter-blue modern-page">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="club-hero">
                  <div className="club-hero-left">
                    <img src={ClubDinggoIllustration} alt="Club DingGo" className="club-logo"/>
                  </div>
                  <div className="club-hero-right">
                    <h1 className="modern-h1 text-blue">Here to help you 24/7/365</h1>
                    <p className="text-blue text-description">
                      Club DingGo is your personal Crash Management repair helper that takes care of you from the
                      moment you have an accident to getting your car repaired and back on the road.
                    </p>
                    {false && (
                      <div className="button-link-combo m40">
                        <Link to="/demo" className="btn btn-rectangular m-right-20">
                          Join Club DingGo Today
                        </Link>
                      </div>
                    )}
                    <div className="contact-cta">
                      <div className="contact-cta-top">
                        <div className="contact-cta-text">Had an accident? Call us on</div>
                        <div className="contact-cta-phone">1300 674 402</div>
                      </div>
                      <div className="contact-cta-bottom">
                        <div className="contact-cta-text">
                          Or make a claim at <a href="https://club.dinggo.com.au" className="link">
                          club.dinggo.com.au
                          <img src={ExternalLinkIcon} alt="Club DingGo claims link"/>
                        </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-7 bg-light-purple modern-page">
          <div className="container">
            <div className="row m20">
              <div className="col-8">
                <h2 className="modern-h2 text-blue text-left">What do you get for being part of the Club?</h2>
              </div>
            </div>
            <div className="row justify-content-center m40">
              <div className="col-12">
                <div className="club-features">
                  <div className="club-feature">
                    <img src={IllustrationTow} alt="Tow" className="club-feature-icon"/>
                    <div className="club-feature-description">
                      24/7/365 accident roadside assistance,
                      helping ensure you are safe and supported when you have an accident.
                    </div>
                  </div>
                  <div className="club-feature">
                    <img src={IllustrationSpanner} alt="Spanner" className="club-feature-icon"/>
                    <div className="club-feature-description">
                      We manage the entire accident and repair process for you.
                    </div>
                  </div>
                  <div className="club-feature">
                    <img src={IllustrationPhone} alt="Phone" className="club-feature-icon"/>
                    <div className="club-feature-description">
                      Don&apos;t waste hours on hold with your insurer again with all
                      insurance claims lodgement and management handled for you.
                    </div>
                  </div>
                  <div className="club-feature">
                    <img src={IllustrationKeys} alt="Keys" className="club-feature-icon"/>
                    <div className="club-feature-description">
                      Replacement vehicles organised for all not at fault claims or
                      if included in your policy.
                    </div>
                  </div>
                  <div className="club-feature">
                    <img src={IllustrationSupport} alt="Support" className="club-feature-icon"/>
                    <div className="club-feature-description">
                      We are always there when you need the help with
                      24/7 phone support and online access with a self serve portal
                      to get updates on your repair and claims progress.
                    </div>
                  </div>
                  <div className="club-feature">
                    <img src={IllustrationRating} alt="Rating" className="club-feature-icon"/>
                    <div className="club-feature-description">
                      We manage the entire accident and repair process for you.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m20">
              <div className="col-12 text-center">
                <h3 className="modern-h3 text-blue">Watch a walkthrough video</h3>
                <iframe
                  className="club-youtube"
                  width="900"
                  height="500"
                  src="https://www.youtube.com/embed/t7X3UA7_pbw"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </section>

        <section className="py-7 bg-light-orange">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <h2 className="modern-h2 text-orange text-center">How Club DingGo Works</h2>
              </div>
            </div>
            <div className="row justify-content-center align-items-stretch">
              <div className="col-12">
                <div className="club-steps">
                  <div className="club-step">
                    <img src={ProdIllustration1} alt="Product Illustration" className="club-step-illustration"/>
                    <div className="club-step-text">
                      <div className="club-step-number">
                        Step 1
                      </div>
                      <div className="club-step-heading">
                        Contact Club DingGo
                      </div>
                      <div className="club-step-description">
                        When you have an accident you can simply contact Club DingGo:
                      </div>
                      <div className="contact-cta">
                        <div className="contact-cta-top">
                          <div className="contact-cta-text">Call us on</div>
                          <div className="contact-cta-phone">1300 674 402</div>
                        </div>
                        <div className="contact-cta-bottom">
                          <div className="contact-cta-text">
                            Or make a claim at <a href="https://club.dinggo.com.au" className="link">
                              club.dinggo.com.au
                              <img src={ExternalLinkIcon} alt="Club DingGo claims link"/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="club-step">
                    <img src={ProdIllustration2} alt="Product Illustration" className="club-step-illustration"/>
                    <div className="club-step-text">
                      <div className="club-step-number">
                        Step 2
                      </div>
                      <div className="club-step-heading">
                        Give us some details
                      </div>
                      <div className="club-step-description">
                        Enter the your details and a few photos of the accident yourself
                        in our online form or you can chat through them over the phone with us.
                        <br/><br/>
                        One quick conversation (anytime) and we will help you with whatever
                        support you need including getting safe, towing your vehicle and
                        managing other parties in the accident.
                      </div>
                    </div>
                  </div>
                  <div className="club-step">
                    <img src={ProdIllustration3} alt="Product Illustration" className="club-step-illustration"/>
                    <div className="club-step-text">
                      <div className="club-step-number">
                        Step 3
                      </div>
                      <div className="club-step-heading">
                        We get your repair quoted
                      </div>
                      <div className="club-step-description">
                        We send your repairs out for quote in our extensive network
                        of repairers and determine if you need to make an insurance claim
                        or not. We find the best repairer for the job at the best price
                        and close to you.
                      </div>
                    </div>
                  </div>
                  <div className="club-step">
                    <img src={ProdIllustration4} alt="Product Illustration" className="club-step-illustration"/>
                    <div className="club-step-text">
                      <div className="club-step-number">
                        Step 4
                      </div>
                      <div className="club-step-heading">
                        We lodge with your insurer
                      </div>
                      <div className="club-step-description">
                        We lodge your insurance claim directly with your insurer on your
                        behalf, booking a replacement vehicle if needed and doing all the
                        painful stuff to ensure you get back on the road as quickly as possible.
                      </div>
                    </div>
                  </div>
                  <div className="club-step">
                    <img src={ProdIllustration5} alt="Product Illustration" className="club-step-illustration"/>
                    <div className="club-step-text">
                      <div className="club-step-number">
                        Step 5
                      </div>
                      <div className="club-step-heading">
                        You’re Always In The Loop
                      </div>
                      <div className="club-step-description">
                        We keep you updated throughout the entire process, so you know the
                        exact status of your job/claim and all updates till you’re back on
                        the road. Plus you can contact us anytime if you need to.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="py-7 bg-light-purple">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="modern-h2 text-blue text-center">
                  Join Club DingGo Today And Enjoy These Amazing Benefits of Membership
                </h2>
              </div>
            </div>
            <div className="row justify-content-left">
              <div className="col-12">
                <div className="club-benefits">
                  <img src={ClubDinggoIllustration} alt="Club DingGo" className="club-benefits-illustration"/>
                  <div className="club-benefits-text">
                    <ul className="club-benefits-list">
                      <li>24/7/365 accident roadside assistance.</li>
                      <li>We manage the entire accident and repair process for you.</li>
                      <li>All insurance claims lodgement and management handled for you.</li>
                      <li>Replacement vehicles organised for you.</li>
                      <li>Easy, 24/7 repair and claim status updates, including self serve portal.</li>
                      <li>The best crash and repair experience regardless of insurer</li>
                    </ul>
                    {false && (
                      <div className="m40">
                        Sounds like the help you need?
                      </div>
                    )}
                    {false && (
                      <div>
                        <Link to="/demo" className="btn btn-rectangular">
                          Join Club DingGo Today
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ClubDinggo;